import React from "react";
import { ElfsightWidget } from 'react-elfsight-widget';
import "./styles.scss";

function HomeInstagram() {
	return (
		<>
			<section>
				<div className="home-instagram">
					<div className="home-instagram__header">
						<div className="home-instagram__heading-box">
							<h2 className="home-instagram__heading">#shenanigrams</h2>
						</div>
					</div>
					
					{'See the shenanigans we get up to' && (
						<p className="home-instagram__subheading">See the shenanigans we get up to</p>
					)}

					<ElfsightWidget widgetID="c7a8ee28-0516-408c-bc2c-8733624af559" />
				</div>
			</section>
		</>
	);
}

export default HomeInstagram;
