import React from "react";
import "./styles.scss";
import shopifyPlusImg from "./Shopify_Plus.svg";
import bigCommerceImg from "./BigCommerce.svg";
import shopwareImg from "./shopware.svg";

function HomePlatformPartners() {
	return (
		<div className="platform-partners">
			<h2 className="platform-partners__heading">Our Platform Partners</h2>
			<div className="platform-partners__partner-list">
				<a
					href="https://www.shopify.com/plus"
					className="platform-partners__partner"
					target="_blank"
					rel="noreferrer"
				>
					<img src={shopifyPlusImg} alt="Shopify Plus" />
				</a>
				<a
					href="https://www.bigcommerce.com/"
					className="platform-partners__partner"
					target="_blank"
					rel="noreferrer"
				>
					<img src={bigCommerceImg} alt="BigCommerce" />
				</a>
				<a
					href="https://www.shopware.com/en/?utm_source=irishtitan&utm_medium=partner&utm_campaign=integration-partner-us&utm_content=partner_website"
					className="platform-partners__partner"
					target="_blank"
					rel="noreferrer"
				>
					<img src={shopwareImg} alt="Shopware" />
				</a>
			</div>
		</div>
	);
}

export default HomePlatformPartners;
