import React from "react";
import { Link } from "gatsby";
import Polygon from "./polygon.svg";
import CapabilitiesCircleSVG from "./CapabilitiesCircle.js";
import "./styles.scss";

function HomeCapabilities() {
	return (
		<div className="home-capabilities">
			<section className="section section--is-spacious">
				<div className="container">
					<div className="home-capabilities__container">
						<div className="home-capabilities__description">
							<h2 className="home-capabilities__heading">
								Full-Service Ecommerce
								<div className="home-capabilities__polygon">
									<img src={Polygon} alt="" aria-hidden="true" />
								</div>
							</h2>
							<p>
								Simply everything you need to build and grow ecommerce channels are right here.
								Our Titans strategize, design, and develop better than the rest. 
								We're seriously sharp and ready to suit up for you.
							</p>
							<Link to="/capabilities" className="btn btn--black-green">
								See our capabilities
							</Link>
						</div>
						<div className="home-approach__capabilities-circle">
							<CapabilitiesCircleSVG />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default HomeCapabilities;
