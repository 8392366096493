import React, { useRef } from "react";
import { Link } from "gatsby";
import Polygon from "./polygon.svg";
import GoldenCircle from "./golden-circle.svg";
import "./styles.scss";

function HomeApproach() {
	const approachSectionRef = useRef(null);

	return (
		<section
			className="section section--is-spacious section--is-green"
			ref={approachSectionRef}
		>
			<div className="home-approach">
				<div className="golden-circle">
					<img src={GoldenCircle} alt="" aria-hidden="true" />
				</div>
				<div className="container">
					<div className="home-approach__container">
						<div className="home-approach__description">
							<h2 className="home-approach__heading">
								Our Approach to Your Business
								<div className="home-approach__polygon">
									<img src={Polygon} alt="" aria-hidden="true" />
								</div>
							</h2>
							<p>
								We've never been accused of being quiet BUT we love to listen. 
								We learn about your business, listening for pain, potential obstacles, and your exact goals for the project.
								Then it's time for some action. Outlining, drafting, ideating, that kind of stuff. 
								We bring our full suite of designers, engineers, and ecommerce strategists to execute that plan. 
								Successful launch, no snags, then we start growing the kingdom.
							</p>
							<Link to="/approach" className="btn btn--black-white">
								Our approach
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeApproach;
