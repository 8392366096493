import React from "react";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/pagination';
import "./styles.scss";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

SwiperCore.use([Navigation, A11y, Pagination]);

function HomeTestimonials() {
	return (
		<section className="section section--is-dark section--is-spacious">
			<div className="testimonials">
				<div className="container">
					<h2 className="testimonials__heading">What they're saying</h2>
					<div className="testimonials__container">
						<button className="testimonials__nav-icon testimonials____nav-icon--prev swiper-button-prev">
							<MdNavigateBefore aria-hidden="true" size={32} />
							<span className="visually-hidden">Previous</span>
						</button>
						<Swiper
							spaceBetween={8}
							slidesPerView={1}
							pagination={{ clickable: true }}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							autoHeight={true}
						>
									<SwiperSlide
										className="testimonials__testimonial"
									>
										<div className="testimonials__content">
											<div className="testimonials__text">
												&ldquo;
													The team at Irish Titan worked their tails off, 
													going above and beyond our vision to strategize and bring our business online for the first time.
												&rdquo;
											</div>
											<div className="testimonials__person">Stacey Robjent</div>
											<div className="testimonials__person-info">Tradehome Shoes, CFO</div>
										</div>
									</SwiperSlide>
									<SwiperSlide
										className="testimonials__testimonial"
									>
										<div className="testimonials__content">
											<div className="testimonials__text">
												&ldquo;
													It doesn’t matter how nice of a car you build, if you put crappy gas in, it’s not going to go very fast. 
													This is the fuel of your website.I’m an engineer, I thought if you had the right products at the right price, 
													they would sell but people have to know about them.
												 &rdquo;
											</div>
											<div className="testimonials__person">Matthew Loeffler</div>
											<div className="testimonials__person-info">FORCE America, VP of Products and Engineering</div>
										</div>
									</SwiperSlide>
									<SwiperSlide
										className="testimonials__testimonial"
									>
										<div className="testimonials__content">
											<div className="testimonials__text">
												&ldquo;
													As farmers, technology has always been more than intimidating. 
													The learning curve in launching a new D2C ecommerce site gave us much trepidation.
													I'm grateful for Irish Titan's wonderful ability to organize, evaluate and execute projects like ours. 
													Working with them was a pleasure, and I'm already excited for future projects with them. 
												&rdquo;
											</div>
											<div className="testimonials__person">Jenni Harris</div>
											<div className="testimonials__person-info">White Oak Pastures, Director of Marketing</div>
										</div>
									</SwiperSlide>
						</Swiper>
						<button className="testimonials__nav-icon testimonials__nav-icon--next swiper-button-next">
							<span className="visually-hidden">Next</span>
							<MdNavigateNext aria-hidden="true" size={32} />
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeTestimonials;
