import React from "react";
import "./styles.scss";

function HomeVideo() {
	return (
		<div className="home-video">
			<section className="section section--is-spacious">
				<div className="container container--is-narrow">
					<div className="home-video__video-wrapper">
						<iframe
							src="https://www.youtube.com/embed/C_5D6_JqKZE?rel=0"
							title="Irish Titan Introduction"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</div>
			</section>
		</div>
	);
}

export default HomeVideo;
