import React from "react";
import HomePlatformPartners from "../HomePlatformPartners";
import HomeCaseStudies from "../HomeCaseStudies";
import PolygonDesktop from "./polygon-desktop.svg";
import PolygonMobile from "./polygon-mobile.svg";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import BREAKPOINTS from "../../../utils/breakpoints";
import "./styles.scss";

function HomeHero() {

	const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.LARGE.VALUE}px)`);

	return (
		<div className="home-hero">
			<section className="section">
				<div className="container">
					<h1 className="home-hero__heading">
						We Build and Grow Ecommerce Channels
						<div className="home-hero__polygon">
							<img
								src={isDesktop ? PolygonDesktop : PolygonMobile}
								alt=""
								aria-hidden="true"
							/>
						</div>
					</h1>
					<HomePlatformPartners />
					<HomeCaseStudies />
				</div>
			</section>
		</div>
	);
}

export default HomeHero;
