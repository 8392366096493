import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/pagination';
import "./styles.scss";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import ArticleCard from "../../ArticleCard";
import HeadingVector from "./heading-vector.svg";

SwiperCore.use([Navigation, A11y, Pagination]);

function HomeMusings() {
	const data = useStaticQuery(graphql`
		query {
			
			allContentfulArticle(
				limit: 6
				sort: { fields: publishedDate, order: DESC }
			) {
				edges {
					node {
						contentful_id
						title
						slug
						previewImage {
							gatsbyImageData(
								placeholder: NONE
								formats: [AUTO, WEBP]
								aspectRatio: 1.64
							)
							description
							title
						}
						heroImage {
							gatsbyImageData(
								placeholder: NONE
								formats: [AUTO, WEBP]
								aspectRatio: 1.64
							)
							description
							title
						}
						category {
							title
						}
						excerpt {
							excerpt
						}
					}
				}
			}
		}
	`);

	const musings = data.allContentfulArticle.edges;

	return (
		<section className="section section--is-dark">
			<div className="container">
				<div className="home-musings">
					<div className="home-musings__heading-image">
						<img src={HeadingVector} aria-hidden="true" alt="" />
					</div>
					<h2 className="home-musings__heading">#musings</h2>
					<p className="home-musings__subheading">Articles from the future of ecommerce</p>
					<div className="home-musings__articles">
						<button className="home-musings__nav-icon home-musings____nav-icon--prev swiper-button-prev">
							<MdNavigateBefore aria-hidden="true" size={32} />
							<span className="visually-hidden">Previous</span>
						</button>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							breakpoints={{
								768: {
									slidesPerView: 2,
								},
								1024: {
									slidesPerView: 3,
								},
							}}
						>
							{musings.map(musing => {
								const {
									contentful_id,
									title,
									previewImage,
									heroImage,
									category,
									slug,
									excerpt: { excerpt },
								} = musing.node;

								return (
									<SwiperSlide
										className="home-musings__testimonial"
										key={contentful_id}
									>
										<ArticleCard
											key={title}
											previewImage={previewImage}
											heroImage={heroImage}
											category={category.title}
											title={title}
											excerpt={excerpt}
											slug={slug}
										/>
									</SwiperSlide>
								);
							})}
						</Swiper>
						<button className="home-musings__nav-icon home-musings__nav-icon--next swiper-button-next">
							<span className="visually-hidden">Next</span>
							<MdNavigateNext aria-hidden="true" size={32} />
						</button>
					</div>
					<div className="home-musings__view-more">
						<Link to="/news" className="btn btn--white-orange">
							View more
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeMusings;
