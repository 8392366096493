import React from "react";
import { Link } from "gatsby";
import "./styles.scss";
import tradeHomeImg from "./tradehome.png"
import surdyksImg from "./surdyks.png"
import forceImg from "./Force.png"

function HomeCaseStudies() {
	return (
		<div className="case-studies">
			<div className="case-studies__list">
				<div style={{ backgroundImage: `linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, #232323 100%), url(${tradeHomeImg})`}}>
					<Link to={`/tradehome-shoes`} className="case-studies__study" >
						<div className="case-studies__study-text"> Trade Home Shoes </div>
					</Link>
				</div>
				<div style={{ backgroundImage: `linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, #232323 100%), url(${surdyksImg})`}}>
					<Link to={`/surdyks`} className="case-studies__study" >
						<div className="case-studies__study-text"> Surdyk's </div>
					</Link>
				</div>
				<div style={{ backgroundImage: `linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, #232323 100%), url(${forceImg})`}}>
					<Link to={`/force-america`} className="case-studies__study" >
						<div className="case-studies__study-text"> FORCE America </div>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default HomeCaseStudies;
