import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SearchEngineOptimization from "../components/SearchEngineOptimization";
import HomeHero from "../components/HomePage/HomeHero";
import HomeApproach from "../components/HomePage/HomeApproach";
import HomeVideo from "../components/HomePage/HomeVideo";
import HomeTestimonials from "../components/HomePage/HomeTestimonials";
import HomeCapabilities from "../components/HomePage/HomeCapabilities";
import HomeMusings from "../components/HomePage/HomeMusings";
import HomeInstagram from "../components/HomePage/HomeInstagram";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query HomeData {
			site {
				siteMetadata {
					defaultDescription
					defaultImage
					author
					url
					slogan
				}
			}
		}
	`);

	const { defaultDescription: description, defaultImage: image, author, url, slogan } = data.site.siteMetadata;

	return (
		<Layout>
			<Helmet>
				<script
					src="https://cdn.embedly.com/widgets/platform.js"
					type="text/javascript"
				/>
				<script type="application/ld+json">
					{`{
						"@context" : "http://schema.org",
						"@type" : "Organization",
						"brand": "${author}",
						"description": "${description}",
						"name" : "${author}",
						"logo" : "${image}",
						"url" : "${url}",
						"slogan": "${slogan}"
					}`}
				</script>
			</Helmet>
			<SearchEngineOptimization title="Home" />
			<HomeHero />
			<HomeApproach />
			<HomeVideo />
			<HomeTestimonials />
			<HomeCapabilities />
			<HomeMusings />
			<HomeInstagram />
		</Layout>
	);
};

export default IndexPage;